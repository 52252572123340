/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./roboto-condensed-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
  url('./roboto-condensed-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto-condensed-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto-condensed-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./roboto-condensed-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto-condensed-v16-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}