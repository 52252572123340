#nav-texture
{
  @nav-size:50px;
  @nav-space:5px;

  position: absolute;
  right:calc(@nav-size + @nav-space);
  z-index: 1000;
  height: 100%;
  top:5px;
  //background-color: green;

  > ul{
    position: absolute;
    display: flex;
    flex-direction: column;
    > li{
      position: relative;
      //background: red;
      list-style: none;

      > ul{
        display: inline-flex;
        position: relative;
        flex-direction: row;
        transition: 0.7s transform ease-in-out;
        margin: 10px 0;

        &:hover
        {
          transform: translateX(-100%) translateX(@nav-size);
        }

        >li{
          list-style: none;
          width: @nav-size;
          height: @nav-size;
          background-color: black;
          //border: 1px solid white;
          color:white;
          margin: 0 @nav-space;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          //border-radius: 100%;
          background-size: contain;
          cursor: pointer;
          box-shadow:  0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
          border-radius: 100%;
          font-size: 0.8em;

          &:first-child{
            margin-left:0;
          }

          &.cat-name
          {
            background-color: rgba(255, 255, 255, 0.6);
            color:black;
          }
        }


      }
      //position: absolute;

    }
  }
}