@import "pub/reset";
@import "pub/fonts/roboto-condensed";

body{
  background-color: black;
  overflow: hidden;
  font-family: 'Roboto Condensed', sans-serif;
}

#download-zip{
  position: absolute;
  z-index: 1000;
  bottom:5px;
  right:5px;
  cursor: pointer;
  display:flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow:  0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  padding: 5px;
  border-radius: 5px;
}

.loader{
  position: absolute;
  z-index: 1010;
  width: 200px;
  height: 200px;
  left: 50%;
  top:50%;
  margin: -100px 0 0 -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-out;
  pointer-events: none;

  &.complete{
    opacity: 0;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@import "pub/model-viewer";
@import "pub/nav";

