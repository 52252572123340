/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: currentColor;
}
/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('roboto-condensed-v16-latin-regular.7cc6ddb2.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url('roboto-condensed-v16-latin-regular.7cc6ddb2.eot') format('embedded-opentype'), /* IE6-IE8 */ url('roboto-condensed-v16-latin-regular.4439b991.woff2') format('woff2'), /* Super Modern Browsers */ url('roboto-condensed-v16-latin-regular.b1cd0d31.woff') format('woff'), /* Modern Browsers */ url('roboto-condensed-v16-latin-regular.59b7aa54.ttf') format('truetype'), /* Safari, Android, iOS */ url('roboto-condensed-v16-latin-regular.4fc1fdf1.svg') format('svg');
  /* Legacy iOS */
}
body {
  background-color: black;
  overflow: hidden;
  font-family: 'Roboto Condensed', sans-serif;
}
#download-zip {
  position: absolute;
  z-index: 1000;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 5px;
  border-radius: 5px;
}
.loader {
  position: absolute;
  z-index: 1010;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  margin: -100px 0 0 -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-out;
  pointer-events: none;
}
.loader.complete {
  opacity: 0;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#ModelViewer {
  position: absolute;
  top: 0;
  left: 0;
}
#ModelViewer .gradiant {
  background: radial-gradient(#a0a0a0, #5a5a5a);
}
#nav-texture {
  position: absolute;
  right: calc(50px + 5px);
  z-index: 1000;
  height: 100%;
  top: 5px;
}
#nav-texture > ul {
  position: absolute;
  display: flex;
  flex-direction: column;
}
#nav-texture > ul > li {
  position: relative;
  list-style: none;
}
#nav-texture > ul > li > ul {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  transition: 0.7s transform ease-in-out;
  margin: 10px 0;
}
#nav-texture > ul > li > ul:hover {
  transform: translateX(-100%) translateX(50px);
}
#nav-texture > ul > li > ul > li {
  list-style: none;
  width: 50px;
  height: 50px;
  background-color: black;
  color: white;
  margin: 0 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  font-size: 0.8em;
}
#nav-texture > ul > li > ul > li:first-child {
  margin-left: 0;
}
#nav-texture > ul > li > ul > li.cat-name {
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
}

/*# sourceMappingURL=index.css.map */
